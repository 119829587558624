<template>
  <div class="Container">
    <img :src="require(`../assets/Icons/${icon}`)"/>
    <h1>{{ title }}</h1>
    <div v-for="d in description" :key="d">
      <p style="font-size:0.9em">{{d}}</p>
      <div style="height: 8px"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiciosItem",
  props: {
    icon: String,
    title: String,
    description: Array
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/global';

.Container {
  margin: 1em;
  display: inline-block;
  width: 15em;
  height: 30em;
  padding: 1em;
  text-align: center;
  border: $primary solid 1px;
  border-radius: 1em;
  transition: 0.5s;
  background-color: $text;

  img {
    width: 50%;
  }
  
  h1{
    margin: 0.5em 0;
    color: $primary;
  }
}

</style>