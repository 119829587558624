<template>
  <div id="Footer">
    <p>© Copyright SilesComm 2022</p>
    <p>Diseñado y Programado por Gabriel Sattler</p>
  </div>
</template>

<script>
export default {
  name: "page-footer"
}
</script>

<style lang="scss" scoped>
@import '../assets/global';

#Footer {
  width: 100%;
  height: 5em;
  background: $secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  p{
    display: block;
    margin: 0.25em 0;
  }
}
</style>