<template>
  <div Id="Inicio">
    <div class="header">
      <h1>Nosotros</h1>
      <div class="separator"/>
      <p>Brindamos seguridad y tranquilidad a nuestros clientes, la mejor opción de acuerdo a sus necesidades.</p>
    </div>
    <div class="body">
      <h1>
        Soluciones e Infraestructuras Tecnológicas
      </h1>
      <p>
        Silescomm es una empresa con más de 19 años de trayectoria en el sector de las comunicaciones, ofreciendo
        servicios, soluciones y productos para empresas en toda la Argentina.
        Llevamos a cabo el desarrollo y adecuación tecnológica de sus instalaciones e infraestructuras: Instalación de
        cableado estructurado, Fibra Óptica, Redes Wifi, auditoría tecnológica, mantenimiento informático, redes de voz
        y datos.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "screen-inicio"
}
</script>

<style lang="scss" scoped>
@import '../assets/global';

#Inicio {
  padding-top: 5em;
  height: calc(100vh - 5em);
  width: $width;
  padding: 0 $side-margin;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $black;

  .body {
    display: block;

    h1 {
      text-align: center;
      color: $primary;
    }

    p {
      margin: 1em 0;
      text-align: center;
    }
  }
}
</style>