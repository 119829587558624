<template>
  <div id="Clientes">
    <div class="Container">
      <div class="header">
        <h1>Clientes</h1>
        <div class="separator"/>
        <p>Estos clientes confiaron en nuestras soluciones.</p>
      </div>
      <div class="body">
        <div v-for="i in clients" :key="i" class="cliente">
          <img :class="{'invert': i.invert}" :src="i.url"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenClientes",
  data() {
    return {
      clients: [
        {
          url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXJtNQXciUco1b0lq849ANagRVFKL77vi2rGurSEbheQfmCHJqguYkHwB69p7pV21VB2A&usqp=CAU",
          invert: false
        },
        {url: "https://profile.com.ar/web/img/logo-profile1.png", invert: true},
        {url: "https://www.acacoop.com.ar/images/logo.png", invert: true},
        {url: "http://www.soporteparapymes.com/assets/img/logo.png", invert: false},
        {url: "https://www.mebamutual.com/wp-content/uploads/2020/09/logo-meba-mutual-website.png", invert: false},
        {url: "https://www.drogmonumento.com.ar/images/Logo-monumento-azul.svg", invert: false},
        {url: "https://www.macro.com.ar/imagen/macrologoheader2020/macro_gral.png", invert: false},
        {url: "http://www.deprop.com.ar/assets/img/logo/dpblanca.png", invert: true},
        {url: "https://escribanos-stafe2da.org.ar/wp-content/uploads/2018/07/logo-125.png", invert: false},
        {url: "https://www.cpcesfe2.org.ar/wp-content/themes/consejo/img/logo.png", invert: false},
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/global';

.invert {
  filter: invert(1);
}

#Clientes {
  margin-top: -10%;
  height: 100vh;
  width: $width;
  padding: 0 $side-margin;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $black;

  .Container {
    width: 100%;

    .body {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 0 10%;
      width: 80%;

      .cliente {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10em;
        height: 15em;
        margin: 0.25em;
        opacity: 0.3;
        transition: 0.5s;

        &:hover {
          opacity: 1;
          transform: scale(102%);
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

</style>