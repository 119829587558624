<template>
  <div class="Container">
    <img src="../assets/BlackWeb.png"/>
    <ul>
      <li><a href="#Inicio">Empresa</a></li>
      <li><a href="#Servicios">Servicios</a></li>
      <li><a href="#Clientes">Clientes</a></li>
      <li><a href="#Contacto">Contacto</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "nav-bar"
}
</script>

<style lang="scss" scoped>
@import '../assets/global';

.Container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30%);
  height: 5em;
  background-color: $primary;
  padding: 0 15%;
  z-index: 10;

  img {
    height: 3em;
    filter: invert(1) contrast(200);
  }

  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      padding: 0 1vw;
      transition: 0.5s;
      transform: scale(1);

      &:hover {
        transform: scale(1.15);
        opacity: 0.8;

        a {
          color: $tertiary;
        }
      }
      
      &:last-child {
        padding-right: 0;
      }

      a {
        color: $text;
        text-decoration: none;
        font-size: 1.5em;
        transition: 0.5s;
      }
    }
  }
}
</style>