<template>
  <div Id="Servicios">
    <div class="header">
      <h1>Servicios</h1>
      <div class="separator"/>
      <p>
        Los servicios de asesoramiento están específicamente diseñados para satisfacer tanto los requerimientos de la industria, comercio y demás entidades.
      </p>
    </div>
    <div class="body">
      <ServiciosItem
          v-for="s in servicios"
          :key="s"
          :description="s.description"
          :icon="s.icon"
          :title="s.title"
          class="servicio"
      />
    </div>
  </div>
</template>

<script>
import ServiciosItem from "@/components/ServiciosItem";

export default {
  name: "ScreenServicios",
  components: {
    ServiciosItem
  },
  data() {
    return {
      servicios: [
        {
          icon: "camera.png",
          title: "Camaras de Seguridad",
          description: ["Diseño e instalación de:",
            "CCTV tanto analógico como ip, con inteligencia artificial y de protección de grandes perímetros mediante cámaras termo graficas."]
        },
        {
          icon: "cable.png",
          title: "Cableado Estructurado",
          description: ["Diseño, instalación y conectorizado de:",
            "- Redes de cables de Fibra Óptica monomodo y multimodo.",
            "- Redes de cableado estructurado UTP para datos y telefonía en categorías 5 y 6.",
            "- Sistemas de UPS para salas de servidores y grupos de trabajo.",
            "- Redes Wireless.",
            "- Sistemas de telefonía análoga."]
        },
        {
          icon: "power.png",
          title: "Canalizaciones eléctricas",
          description: ["Instalaciones eléctricas interiores y exteriores, Industriales e Institucionales.",
            "Instalaciones eléctricas por bandejas portacables y cañerías.",
            "Canalización de Fuerza Motriz Industrial.",
            "Montaje de Tableros Industriales"]
        },
        {
          icon: "fiber.png",
          title: "Fibra Óptica",
          description: ["Realizamos tendidos, conectorización, fusiones y diseños de redes de fibra óptica de acuerdo a las necesidades de su industria o comercio."]
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/global';

#Servicios {
  margin-top: -10%;
  width: $width;
  padding: 0 $side-margin;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $black;
  text-align: justify;

  .body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;

    .servicio {
      &:hover {
        transform: scale(101%);
        filter: drop-shadow(0px 0px 12px $primary);
      }
    }
  }
}

</style>