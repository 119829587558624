<template>
  <div id="Contacto">
    <div class="header">
      <h1>Contacto</h1>
      <div class="separator"/>
      <p>Su consulta no molesta</p>
    </div>
    <div class="body">
      <div class="contactoContainer">
        <div class="contactoItem">
          <img src="~@/assets/Icons/mail.png">
          <div>
            <h1>Email:</h1>
            <p><a href="mailto:info@silescomm.com">
              info@silescomm.com
            </a></p>
          </div>
        </div>
        <div class="contactoItem">
          <img src="~@/assets/Icons/location.png">
          <div>
            <h1>Dirección:</h1>
            <p>Tucumán 2758 Depto 3<br/>Rosario, Santa Fe, Argentina</p>
          </div>
        </div>
        <div class="contactoItem">
          <img src="~@/assets/Icons/phone.png">
          <div>
            <h1>Telefonos:</h1>
            <p>341-2411568</p>
            <p>341-7926079</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenContacto"
}
</script>

<style lang="scss" scoped>
@import '../assets/global';

#Contacto {
  margin-top: -10%;
  height: 100vh;
  width: $width;
  padding: 0 $side-margin;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $black;

  .body {
    width: 100%;

    .contactoContainer {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      .contactoItem {
        width: 25%;
        padding: 1em;
        display: inline-block;
        text-align: center;
        border: $primary solid 1px;
        border-radius: 1em;
        background: $text;
        transition: 0.5s;

        &:hover {
          transform: scale(101%);
          filter: drop-shadow(0px 0px 12px $primary);
        }

        div {
          width: auto;
          padding: 3%;
        }
      }
    }
  }
}

h1 {
  color: $secondary;
}

a {
  color: $secondary;
  text-decoration: none;
}

</style>