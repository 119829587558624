<template>
  <NavBar/>
  <ScreenInicio/>
  <ScreenServicios/>
  <ScreenClientes/>
  <ScreenContacto/>
  <PageFooter/>
</template>

<script>
import NavBar from "@/components/nav-bar";
import ScreenInicio from "@/components/screen-inicio";
import ScreenServicios from "@/components/ScreenServicios";
import ScreenClientes from "@/components/ScreenClientes";
import ScreenContacto from "@/components/ScreenContacto";
import PageFooter from "@/components/page-footer";

export default {
  name: 'App',
  components: {
    PageFooter,
    NavBar,
    ScreenInicio,
    ScreenServicios,
    ScreenClientes,
    ScreenContacto
  }
}
</script>
<style lang="scss">
@import './assets/global';

#app {
  margin: 0;
  width: 100%;

  background-color: $text;
  color: $text;
}
</style>
